
import { createRouter, createWebHashHistory } from "vue-router";
import Layout2 from "../layout/Index.vue";
import Layout1 from "../layout/clientIndex";
const routes = [

  {
    // 登录
    path: "/login",
    name: "Login",
    component: () => import("../views/login/Index.vue"),
    meta: {
      title: "登录",
      requireAuth:true,// true为这个页面需要登录权限
    }
  },

  {
    path: "/managementPage",
    name: "ManagementPage",
    component: Layout2,
    redirect: "/managementPage",
    meta: { title: "系统",requireAuth:true, },
    children: [
      {
        // 首页
        path: "/managementPage",
        name: "managementPage",
        component: () => import("../views/managementPage/Index.vue"),
        meta: { title: "后台首页",requireAuth:true}
      },
      {
        // 栏目管理
        path: "/managementPage/columnPage",
        name: "managementColumnPage",
        component: () => import("../views/columnPage/Index.vue"),
        meta: { title: "栏目管理",requireAuth:true },

      },
      {
        // 新增栏目管理
        path: "/newColumnPage",
        name: "NewColumnPage",
        component: () => import("../views/columnPage/newColumnPage/Index.vue"),
        meta: { title: "新增栏目管理",requireAuth:true }
      },
      {
        // 内容管理
        path: "/managementPage/contentPage",
        name: "managementContentPage",
        component: () => import("../views/contentPage/Index.vue"),
        meta: { title: "内容管理",requireAuth:true },

      },
      {
        // 新增内容管理
        path: "/newContentPage",
        name: "NewContentPage",
        component: () => import("../views/contentPage/newContentPage/Index.vue"),
        meta: { title: "新增栏目管理",requireAuth:true }
      },
      {
        // 公司信息管理
        path: "/companyInforPage",
        name: "CompanyInforPage",
        component: () => import("../views/companyInforPage/Index.vue"),
        meta: { title: "新增栏目管理",requireAuth:true }
      },
    ]
  },
  {
    path: "/",
    name: "Index",
    redirect: "/index",
    component: Layout1,
    meta: { title: "系统" },
    children: [
      {
        // 首页
        path: "/index",
        name: "Index",
        component: () => import("../views/index/Index.vue"),
        meta: { title: "首页" }
      },
      {
        // 关于我们
        path: "/about",
        name: "About",
        component: () => import("../views/about/Index.vue"),
        meta: { title: "关于我们" }
      },
      {
        // 业务领域
        path: "/products",
        name: "Products",
        component: () => import("../views/products/Index.vue"),
        meta: { title: "业务领域" }
      },
      {
        // 解决方案
        path: "/solution",
        name: "Solution",
        component: () => import("../views/solution/Index.vue"),
        meta: { title: "解决方案" }
      },
      {
        // 产品详情页
        path: "/productsDetail",
        name: "productsDetail",
        component: () => import("../views/productsDetail/Index.vue"),
        meta: { title: "产品详情页" }
      },
      {
        // 合作伙伴
        path: "/partner",
        name: "Partner",
        component: () => import("../views/partner/Index.vue"),
        meta: { title: "合作伙伴" }
      },
      {
        // 新闻中心
        path: "/news",
        name: "News",
        component: () => import("../views/news/Index.vue"),
        meta: { title: "新闻中心" }
      },
      {
        // 新闻详情页
        path: "/newsDetail",
        name: "newsDetail",
        component: () => import("../views/newsDetail/Index.vue"),
        meta: { title: "新闻详情页" }
      },
      {
        // 人才招聘
        path: "/joinUs",
        name: "JoinUs",
        component: () => import("../views/joinUs/Index.vue"),
        meta: { title: "人才招聘" }
      },
      {
        // 联系我们
        path: "/contactUs",
        name: "ContactUs",
        component: () => import("../views/contactUs/Index.vue"),
        meta: { title: "联系我们" }
      },
      {
        // 详情页
        path: "/detailPage",
        name: "DetailPage",
        component: () => import("../views/detailPage/Index.vue"),
        meta: { title: "详情页" }
      },
      {
        // 详情中转页
        path: "/transferPage",
        name: "TransferPage",
        component: () => import("../views/detailPage/transferPage.vue"),
        meta: { title: "详情中转页" }
      },
      {
        // 搜索页
        path: "/searchPage",
        name: "searchPage",
        component: () => import("../views/searchPage/Index.vue"),
        meta: { title: "搜索页" }
      },
    ]
  },
  {
    path: "/",
    name: "partyBuild",
    redirect: "/partyBuild",
    meta: { title: "系统" },
    children: [
      {
        // 党建工作
        path: "/partyBuild",
        name: "PartyBuild",
        component: () => import("../views/partyBuild/Index.vue"),
        meta: { title: "党建工作" }
      },
      {
        // 党建工作列表
        path: "/partyBuildList",
        name: "PartyBuildList",
        component: () => import("../views/partyBuildList/Index.vue"),
        meta: { title: "党建工作列表" }
      },
      {
        // 党建工作详情
        path: "/partyDetail",
        name: "PartyDetail",
        component: () => import("../views/partyDetail/Index.vue"),
        meta: { title: "党建工作详情" }
      },
    ]
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})


export default router
